<template>
  <div class="index">
    <div class="box">
      <div v-for="(item, index) in data.total" :key="index" class="item">
        <div class="icon">
          <i :class="icon[index]" />
        </div>
        <div class="text">
          <div class="name">{{ item.name }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">流量趋势</div>
      <div id="myChart" class="myChart" style="width: 100%; height: 540px" />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

import { commonService } from '@/service'

const service = new commonService()

export default {
  name: 'index',
  data() {
    return {
      data: {},
      icon: ['ri-user-add-line', 'ri-group-line', 'ri-group-line', 'ri-team-line'],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.getDataList({
          type: 'user',
        })
        this.data = data
        let myChart = echarts.init(document.getElementById('myChart'))
        myChart.setOption({
          legend: {
            data: ['前端访客数(UV)', '累计访客数', '注册用户数', '累计注册用户数'],
          },
          grid: {
            top: '20px',
            left: '40px',
            bottom: '40px',
            right: '40px',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.data.date,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '前端访客数(UV)',
              data: this.data.uv,
              type: 'line',
            },

            {
              name: '累计访客数',
              data: this.data.cumulative_uv,
              type: 'line',
            },

            {
              name: '注册用户数',
              data: this.data.user,
              type: 'line',
            },

            {
              name: '累计注册用户数',
              data: this.data.cumulative_user,
              type: 'line',
            },
          ],
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" scoped>
.index {
  .box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: calc(25% - 15px);
      height: 120px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      padding: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .icon {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        border-radius: 100%;
        text-align: center;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
      .text {
        flex: 1;
        margin-left: 20px;

        .name {
          font-size: 14px;
          color: #5c5c5c;
        }

        .value {
          font-size: 26px;
          color: #000;
        }
      }
    }
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
  }

  .mytip {
    font-size: 12px;
    color: #5c5c5c;
    float: right;
  }
  .content {
    background: #fff;
    padding: 20px;
  }
}
</style>
